h5 {
  text-transform: uppercase;
}

p {
  margin-top: 0;
}

.container-login {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 18px;
  line-height: 1.4;
  color: #3e4246;
  font-size: 1.1rem;
}

.container-logo {
  max-width: 1320px;
  margin: 18px auto;
  padding: 0 18px;
}

.theme-btn {
  cursor: pointer;
}

.h-login {
  width: 100%;
  padding: 4.25rem 0;
  background-color: #F5F5F5;
  background-image: url("../../src/images/rating_hub_hero.jpg");
  background-size: cover;
  justify-content: space-between;
}

.h-login-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -moz-column-gap: 3em;
  column-gap: 3em;
  row-gap: 2em;
}

.h-login-intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.h-login-intro p {
  /* background-color: "red"; */
  margin-bottom: 0;
  /* font-size: 26px; */

}

.h-login-box {
  background-color: white;
  /* padding: em; */
  /* height:"70%" */
  /* width:"3em" */
}

.h-login-buttons {
  display: flex;
  flex-direction: column;
}

.h-login-input {
  width: calc(100% - 32px);
  background-color: rgb(255, 255, 255);
  border: 1px solid var(--slate-200);
  border-radius: 4px;
  color: rgb(44, 44, 44);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  outline: none;
  padding: 18px 16px;
  margin: 0 0 1em 0px;
}

.h-login-forgot-password {
  align-self: flex-end;
  display: inline-block;
  margin-bottom: 0.5em;
  font-size: 14px;
  text-decoration: underline;
  color: #600;
}

.h-login-forgot-password:hover {
  text-decoration: none;
  color: #3e4246;
}

.h-login-submit {
  align-self: flex-start;
  margin-bottom: "50px";
}

.forgot {
  align-self: flex-start;
  margin-bottom: "50px";
}

.m-key-features {
  padding: 4.5em 0;
}

.m-kf-grid {
  display: grid;
  grid-template-columns: 0.65fr 1fr;
  -moz-column-gap: 3em;
  column-gap: 3em;
  row-gap: 2em;
}

.m-kf-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.m-kf-image img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
}

.m-ra-grid {
  display: grid;
  grid-template-columns: 1fr 0.45fr;
  -moz-column-gap: 3em;
  column-gap: 3em;
  row-gap: 2em;
}

.m-ra-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.m-ra-content a {
  color: #600;
  font-weight: bold;
}

.m-ra-content a:hover {
  color: #3e4246;
  opacity: .8;
  text-decoration: none;
}

.m-ra-image img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
}

.m-ae-grid {
  display: grid;
  grid-template-columns: 0.78fr;
  -moz-column-gap: 3em;
  column-gap: 3em;
}

.m-ae-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.m-ae-content p {
  margin-bottom: 0;
}

.m-ae-content a {
  font-weight: bold;
}

.m-ae-content a:hover {
  opacity: .8;
  text-decoration: none;
}

.f-footer-grid {
  display: grid;
  grid-template-columns: 0.78fr;
  -moz-column-gap: 3em;
  column-gap: 3em;
}

/* FROM er_theme_v1 */
.serif {
  font-family: "Portada", "Times New Roman", serif;
}

.sans-serif {
  font-family: "Inter", Arial, sans-serif;
  color: #600;
}

h1.sans-serif,
h2.sans-serif,
h3.sans-serif,
h4.sans-serif,
h5.sans-serif,
h6.sans-serif {
  font-family: "Inter", Arial, sans-serif;
}

h1.serif,
h1.sans-serif {
  font-size: 3.125rem;
  line-height: 1;
  font-weight: 600;
  margin: 0 0 1.4rem;
  word-break: break-word
}

h2.serif,
h2.sans-serif {
  font-size: 2.375rem;
  line-height: 1.1;
  font-weight: 600;
  margin: 0 0 1.1rem
}

h3.serif,
h3.sans-serif {
  font-size: 2.063rem;
  line-height: 1.1;
  font-weight: 600;
  margin: 0 0 1.1rem
}

h4.serif,
h4.sans-serif {
  font-size: 1.75rem;
  line-height: 1.2;
  font-weight: 700;
  margin: 0 0 1rem
}

h5.serif,
h5.sans-serif {
  font-size: 1.375rem;
  line-height: 1.2;
  font-weight: 700;
  margin: 0 0 .8rem
}

h6.serif,
h6.sans-serif {
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: 700;
  margin: 0 0 .7rem
}

ol li::marker,
ol ul li::marker,
ul li::marker,
ul ul li::marker {
  margin: 0 .5rem 0 0;
  color: #600;
  font-weight: bold
}

ul.checkmark li {
  margin: 0 0 .375rem
}

ul.checkmark {
  margin: 0 0 1.5em 1.9rem;
  padding: 0;
  list-style-type: none
}

ul.checkmark li {
  position: relative
}

ul.checkmark li::marker {
  content: none
}

ul.checkmark li::-webkit-details-marker {
  display: none
}

ul.checkmark li:before {
  content: "";
  position: absolute;
  top: .125em;
  left: -1.688em;
  width: 1em;
  height: 1em;
  max-width: 16px;
  max-height: 16px;
  background-image: url("../../src/images/checkmark-rouge.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center
}

ul.checkmark--rouge li:before {
  background-image: url("../../src/images/checkmark-rouge.svg");
}

.theme-btn {
  border-radius: 2px;
  padding: .9rem 1.5rem;
  background-color: #ccc;
  color: #151617;
  font-size: .938em;
  text-decoration: none;
  font-weight: 500;
  margin: .2rem 0;
  display: inline-block;
  text-align: center;
  border: none;
  transition-duration: .13s
}

.theme-btn:focus,
.theme-btn:active:focus {
  color: #fff;
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(150, 151, 54, .2)
}

.theme-btn:hover,
.theme-btn:active {
  background-color: #eee;
  color: #000;
}

.theme-btn--fullwidth {
  width: 100%;
}

.theme-btn.theme-btn--outline {
  outline: .12em solid #151617;
  outline-offset: -0.14em;
  background-color: rgba(0, 0, 0, 0);
  color: #151617;
}

.theme-btn.theme-btn--outline:hover,
.theme-btn.theme-btn--outline:active {
  background-color: #151617;
  color: #fff;
}

.theme-btn.theme-btn--outline:focus,
.theme-btn.theme-btn--outline:active:focus {
  box-shadow: 0 0 0 .2rem rgba(21, 22, 23, .2)
}

.theme-btn--rouge {
  background-color: #600;
  color: #fff;
}

.theme-btn--rouge.theme-btn--outline {
  color: #600;
  outline-color: #600;
}

.theme-btn--rouge:hover,
.theme-btn--rouge:active {
  background-color: #600;
  color: #fff;
}

.theme-btn--rouge:hover.theme-btn--outline,
.theme-btn--rouge:active.theme-btn--outline {
  background-color: #600;
}

.theme-btn--rouge:focus,
.theme-btn--rouge:active:focus {
  box-shadow: 0 0 0 .2rem rgba(102, 0, 0, .2)
}

.theme-btn--rouge:focus.theme-btn--outline,
.theme-btn--rouge:active:focus.theme-btn--outline {
  box-shadow: 0 0 0 .2rem rgba(102, 0, 0, .2)
}

.theme-btn--rouge-lt {
  background-color: rgba(51, 0, 0, .15);
  color: #151617
}

.theme-btn--rouge-lt.theme-btn--outline {
  color: #151617;
  outline-color: rgba(31, 0, 0, .15)
}

.theme-btn--rouge-lt:hover,
.theme-btn--rouge-lt:active {
  background-color: rgba(51, 0, 0, .25);
  color: #151617
}

.theme-btn--rouge-lt:hover.theme-btn--outline,
.theme-btn--rouge-lt:active.theme-btn--outline {
  background-color: rgba(51, 0, 0, .15);
  color: #151617
}

.theme-btn--rouge-lt:focus,
.theme-btn--rouge-lt:active:focus {
  box-shadow: 0 0 0 .2rem rgba(102, 0, 0, .05);
  color: #151617
}

.theme-btn--rouge-lt:focus.theme-btn--outline,
.theme-btn--rouge-lt:active:focus.theme-btn--outline {
  box-shadow: 0 0 0 .2rem rgba(102, 0, 0, .05);
  color: #151617
}

.shadow {
  box-shadow: 0px 6px 34px -3px rgba(0, 0, 0, .06) !important
}

.rounded--4 {
  border-radius: 4px
}

.ui--light {
  color: #fff
}

.ui--light .heading--caps {
  color: #fff;
  font-weight: 700
}

.ui--light a {
  color: #fff
}

.disclaimer {
  color: #151617;
  font-size: .85rem;
  line-height: 1.3em
}

.module--p-xs {
  padding-top: 2.1875rem;
  padding-bottom: 2.1875rem
}

.module--pb-md {
  padding-bottom: 4.375rem
}

.module--p-lg {
  padding-top: 5.6875rem;
  padding-bottom: 5.6875rem
}

.background--rouge {
  background-color: #600
}

.background--curve-lines-3 {
  background-image: url("../../src/images/background-curves.svg");
  background-position: right center;
  background-size: cover
}

@media (max-width: 767.98px) {

  .f-footer-grid,
  .m-ae-grid {
    grid-template-columns: 1fr;
  }

  .split-grid {
    grid-template-columns: 1fr;
  }
}