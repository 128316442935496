@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Light.ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "portada";
    src: url("./fonts/Portada.ttf");
    font-weight: 400;
    font-style: normal;
}

/* TODO: Potentially add other font weights and styles */

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*::before,
*::after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
    filter: none;
    -webkit-box-shadow: 0 0 0 30px #F8F3F3 inset !important;
    -webkit-text-fill-color: #2C2C2C !important;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#clickableTable tbody tr:hover {
    background: #D6CCBF !important;
}

tr:nth-child(even) {
    background: #FFFFFF;
}

tr:nth-child(odd) {
    background: #F5F3F1;
}

tr.sub {
    /* background: lightgrey !important; */
    background: #eaeaea !important;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes animation-rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes animation-rotate {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-o-keyframes animation-rotate {
    100% {
        -o-transform: rotate(360deg);
    }
}

@keyframes animation-rotate {
    100% {
        transform: rotate(360deg);
    }
}