.accordion {}

.accordion__item+.accordion__item {
    /*border-top: 1px solid rgba(0, 0, 0, 0.1);*/
}

.accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
}

.accordion__button:hover {
    /*background-color: #ddd;*/
}

.accordion__button:before {
    display: inline-block;
    content: '';
    height: 12px;
    width: 12px;
    margin-right: 12px;
    border-bottom: 3px solid currentColor;
    border-right: 3px solid currentColor;
    transform: rotate(45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
    transform: rotate(-135deg);
    margin-top: 6px;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 1.35s ease-in;
}

.plan-type-panel {
    padding: 0px;
}

.plan-type-header .accordion__button {
    background-color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    color: #2C2C2C;
    border-bottom: solid 1px #C8C8C8;
}

.accordion__button:before {
    border-color: #0B6FBE;
    float: right;
}

.coverage-header .accordion__button {
    background-color: #FFFFFF;
    border-bottom: solid 1px #C8C8C8;
    box-shadow: inset 0px 2px 6px 0px rgba(0, 0, 0, 0.14);
    padding-left: 22px;
    border-left: solid 4px #660000;
}

.accordion__button {
    outline: 0;
}

.carrier-header .accordion__button {
    border-bottom: solid 1px #C8C8C8;
    padding-left: 22px;
}

.network-header .accordion__button {
    background-color: #FFFFFF;
    border-bottom: solid 1px #C8C8C8;
    padding-left: 22px;
}



.plans {
    padding: 0px;
}

.accordion table {
    margin: 0px !important;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}